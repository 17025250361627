import React, { useEffect } from "react";
import { Card, Alert, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Page500 = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (
      (state?.errorMsg?.includes("Vpn") || state?.errorMsg?.includes("VPN")) &&
      state.status
    ) {
      const timer = setTimeout(() => {
        window.location.href = state.status;
      }, 10000); // 10000 milliseconds = 10 seconds

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [state?.errorMsg, state.status]);

  return (
    <div className="login-bg intro-area" style={{ padding: 0 }}>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Card className="text-center card-large">
          <Card.Body className="req-border left-form-bg">
            <h4 className="text-red my-4">{"Invalid Request"}</h4>
            <Alert variant="danger">
              {state?.errorMsg || "Something went wrong"}
            </Alert>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Page500;
